import styled from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'
import { rem } from 'polished'

export const Card = styled.article`
  display: flex;
  padding: 100px;
  border-radius: 4px;

  a {
    font-size: ${rem(16)};
    font-weight: 600;
  }

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.babar0)};
    border-bottom: 4px solid ${parseColor(colors.sonic500)};
  `}

  ${media.lessThan('md')} {
    padding: 60px;
  }

  ${media.lessThan('xs')} {
    padding: 40px;
    flex-direction: column;
  }
`
