import React from 'react'

import { Config, Seo } from 'components/helpers'

import View from 'views/error'

const NotFoundPage: React.FC = () => (
  <>
    <Config data={{ platform: null, crumbs: null }} />
    <Seo title="Página não encontrada" />
    <View />
  </>
)

export default NotFoundPage
