import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Block, Title, Text, Vector, Button } from '@superlogica/design-ui'

import { Link } from 'components/helpers'
import * as S from './styled'

const View: React.FC = () => (
  <Block
    tag="section"
    bgColor="babar25"
    paddingTop={{ xxxs: 40, md: 100 }}
    paddingBottom={{ xxxs: 40, md: 100 }}
  >
    <Container>
      <Row justify="center">
        <Col xs={12} md={10}>
          <S.Card>
            <Vector
              name="search-remove"
              width={{ xxxs: 40, sm: 60 }}
              height={{ xxxs: 40, sm: 60 }}
              color="sonic500"
              marginTop={{ xxxs: 0, sm: 8 }}
              marginBottom={{ xxxs: 24, sm: 0 }}
              marginRight={{ xxxs: 0, sm: 48 }}
            />
            <Block tag="header">
              <Title
                size="display01"
                font="heading"
                color="babar900"
                marginBottom={12}
              >
                Página não encontrada
              </Title>
              <Text
                size="display04"
                color="babar700"
                maxWidth={400}
                marginBottom={32}
              >
                Desculpe, mas a página que você está procurando não foi
                encontrada.
              </Text>
              <Button
                to="/"
                title="Voltar para página inicial"
                tag={Link}
                color="sonic500"
                appearance="link"
                leftIcon={<Vector name="arrow-left" color="sonic300" />}
                iconSize={12}
              >
                Voltar para página inicial
              </Button>
            </Block>
          </S.Card>
        </Col>
      </Row>
    </Container>
  </Block>
)

export default View
